
// cache

import Field from "./controls.filter.Field.js";

export default class Slider extends Field {
    constructor(options) {
        super(options);

        this.noAutoSave = true;
    }

    loadStorage() {
        super.loadStorage();
    }


    /* */
    setTempValue(value) {
        this.temp = value;
    }

    applyTempValue() {
        console.log("apply temp value", this.temp);
        this.storage.min = this.temp.min;
        this.storage.max = this.temp.max;
        this.saveStorage();
        this.filter.update();
    }

    hasValue() {
        return !isNaN(parseFloat(this.storage.min)) && !isNaN(parseFloat(this.storage.max));
    }


    getPills() {
        if (this.hasValue()) {
            return [
                {
                    text: `${this.name}: ${this.storage.min} - ${this.storage.max}`,
                    click: () => this.filter.selectField(this),
                    close: () => {
                        this.clear();
                        this.filter.update();
                    },
                }
            ]
        }
        return [];
    }

    getFilterText() {
        if (this.hasValue()) {
            return `${this.storage.min} - ${this.storage.max}`;
        }
        return null;
    }

    getFilterString() {
        if (this.hasValue()) {
            return `[${this.from}] <= ${this.storage.max} AND [${this.to}] >= ${this.storage.min}`;
        }
    }

    getFilterItems() {
        return [
            {
                column: this.from,
                operator: "lessthanorequal",
                value: this.storage.max,
            },
            {
                column: this.to,
                operator: "greaterthanorequal",
                value: this.storage.min,
            },
        ];
    }

    clear() {
        console.log("Slider clear");
        this.storage.min = null;
        this.storage.max = null;
        this.saveStorage();
    }
}
